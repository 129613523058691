import Vue from 'vue'
import VueRouter from 'vue-router'
// const routerPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push (location, onResolve, onReject) {
//   this.history.current.meta.mt = 0;
//   if (onResolve || onReject) {
//     return routerPush.call(this, location, onResolve, onReject);
//   }
//   return routerPush.call(this, location).catch(err => err);
// };
Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/home',
    meta: {
      name: '第二药房'
    }
  },
  {
    path: '/home',
    name: 'homePage',
    component: () => import('../views/home.vue' /* webpackChunkName: "admin" */),
    meta: {
      name: '第二药房'
    },
  },
  {
    path: '/about',
    name: 'aboutPage',
    component: () => import('../views/about.vue' /* webpackChunkName: "admin" */),
    meta: {
      name: '第二药房'
    },
  },
  {path: '*', redirect: '/'}
];
/**
 * 初始化路由
 */
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router;
